import React from "react";
import { Button } from "@mui/material";

import classes from "./large-btn.module.css";

const LargeBtn = (props) => {
  const { label, isDisabled, onClick, showCornors, styles, bgColor } = props;
  const backgroundColor = bgColor || '#E8D33E';
  return (
    <Button
      variant="contained"
      size="large"
      color="primary"
      onClick={onClick}
      disabled={isDisabled}
      className={classes.buttonOverride}
      style={{ ...styles, backgroundColor }}
    >
      {showCornors !== false &&
        <>
          <div className={classes.blCornor}></div>
          <div className={classes.trCornor}></div>
        </>
      }
      <span className={classes.actionButtonText}>
        {label}
      </span>
    </Button>
  );
};

export default LargeBtn;
