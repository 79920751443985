import { Outlet } from "react-router-dom";

import {Header, Footer, Messages, ProjectDetails} from "../components";

function Layout() {
  return (
    <>
      <Messages />
      <Header />
      <ProjectDetails/>
      <Footer />
    </>
  );
}
export default Layout;
