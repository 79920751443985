import {useWeb3Context} from "./hooks";
import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {ADDRESSES, DEFAULT_NETWORK} from "./constants";
import {fetchProjectDetails} from "./store/slices";
import {ProjectDetails} from "./components";
import Layout from "./views/layout";

function App() {
  const { connect, hasCachedProvider, provider } = useWeb3Context();
  const dispatch = useDispatch();
  const [walletChecked, setWalletChecked] = useState(false);

  useEffect(() => {
    if (hasCachedProvider()) {
      connect().then(() => {
        setWalletChecked(true);
      });
    } else {
      setWalletChecked(true);
    }
  }, []);
  React.useEffect(() => {
    Object.values(ADDRESSES).map(address => {
      dispatch(fetchProjectDetails({ address, provider, networkID: DEFAULT_NETWORK }));
    });
  }, []);

  return (<Layout />);
}

export default App;
