import * as React from "react";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../../assets/img/banner.png";

import { Button, IconButton, InputAdornment, OutlinedInput, } from "@mui/material";
import { Done as CheckCircle } from "@mui/icons-material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { useWeb3Context } from "../../hooks";
import {Presale, StableCoin} from "../../abis";
import { ConnectMenu } from "../";
import {
  changeApproval,
  error,
  fetchProjectDetails,
  refundAmount,
  isPendingTxn, refund,
  txnButtonText,
  warning,
} from "../../store/slices";
import "./project-details.scss";
import { ADDRESSES, DEFAULT_NETWORK, getAddress, messages } from "../../constants";
import Logo from "../../assets/img/logo/logo.png"
import {useState} from "react";
import {claimList} from "../../assets/whitelist";

export function ProjectDetails() {
  const [projectLoading, setProjectLoading] = React.useState(false);
  const [activeTab, setactiveTab] = React.useState("p-details");
  const amountToRaised = 120000000000;
  const [multiplier, setMultiplier] = useState(0);
  const [amountRaised, setAmountRaised] = React.useState(0);
  const [refund, setRefund] = React.useState(0);
  const [invested, setInvested] = React.useState(0);
  const [quantity, setQuantity] = React.useState("");
  const [stableCoinBalance, setStableCoinBalance] = React.useState(0);
  const [saleCompleted, setSaleCompleted] = React.useState(false);
  const [finalContri, setFinalContri] = useState(0)
  const [fldxAllocation, setFLDXAllocation] = useState(0)
  const [veNFTAllocation, setVeNFTAllocation] = useState(0)

  let id = ADDRESSES.project1;
  const { provider, address, chainID, checkWrongNetwork } = useWeb3Context();
  const dispatch = useDispatch();

  const projectDetails = useSelector((state) => {
    return state.projects[id];
  });
  React.useEffect(() => {
    if (projectDetails === undefined) {
      dispatch(
          fetchProjectDetails({
            address: id,
            provider,
            networkID: DEFAULT_NETWORK,
          })
      );
    }
  }, []);

  React.useEffect(() => {
    if (projectDetails && projectDetails.loading === false) {
      setProjectLoading(false);
    }
  }, [projectDetails]);

  const pendingTransactions = useSelector((state) => {
    return state.pendingTransactions;
  });

  const projectContract = new ethers.Contract(id, Presale, provider);
  const stableCoinContract = new ethers.Contract(
    getAddress("STABLE_COIN_ADDRESS"),
    StableCoin,
    provider
  );

  const onRefund = async () => {
    if (await checkWrongNetwork()) return;
    else {
      await dispatch(
          refundAmount({
          provider,
          networkID: chainID,
          idoAddress: id,
        })
      );
      setQuantity("");
    }
  };

  const updateRaisedAmounts = async () => {
    const amountToRaise = amountToRaised / Math.pow(10, 6);
    const amountRaised = await projectContract.totalAmountRaised() / Math.pow(10, 6);
    setAmountRaised(amountRaised);
    setMultiplier((amountRaised / amountToRaise).toFixed(3));
  };

  const handleChange = (event, newValue) => {
    setactiveTab(newValue);
  };

  React.useEffect(() => {
    updateRaisedAmounts();
    const interval = setInterval(updateRaisedAmounts, 5000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    if (address) {
      updateUserTokens();
      const interval = setInterval(updateUserTokens, 5000);
      return () => clearInterval(interval);
    }
  }, [address]);

  const updateUserTokens = async () => {
    const obj = claimList.find((_account) => _account[0].toLowerCase() === address.toLowerCase());
    if (obj) {
      setFinalContri(obj[3]);
      setFLDXAllocation(obj[4]);
      setVeNFTAllocation(obj[5]);
    }

    const amountToRaise = amountToRaised / Math.pow(10, 6);
    const invested = await projectContract.userToAmount(address) /Math.pow(10, 6);
    const amountRaised = await projectContract.totalAmountRaised() / Math.pow(10, 6);
    setInvested(invested);

    const refundStatus = await projectContract.userToWithdrawExcessToken(address);
    if (refundStatus === true) {
      setRefund(0);
    } else {
      setRefund( (amountRaised - amountToRaise) * invested / amountRaised);
    }
    await stableCoinContract.balanceOf(address).then((data) => {
      setStableCoinBalance(data / Math.pow(10, 6));
    })
  };

  return (
    <>
      {projectLoading && <p>Loading</p>}
      {!projectLoading && (
        <div className="project-details-wrapper">
          <div className="details mt-5 p-5">
            <div className="d-flex justify-content-between flex-wrap">
              <div className="d-flex align-items-center mb-3">
                <img
                  alt="Project icon"
                  height="55px"
                  width="55px"
                  src={Logo}
                />
                <span className="project-name ms-3 audio-wide">
                  Flair Dex
                </span>
              </div>
              <div className="d-flex  align-items-center mb-3">
                <a className="social-links"
                  href="https://twitter.com/FlairDefi"
                  target="_blank">
                  <i className="bi-twitter"></i>
                </a>

                <a className="social-links"
                  href="https://www.flairdex.xyz/"
                  target="_blank">
                  <i className="bi-globe"></i>
                </a>

                <a className="social-links"
                  href="https://discord.gg/tyV7mwXX"
                  target="_blank">
                  <i className="bi-discord"></i>
                </a>
              </div>
            </div>
            <div>
              <p className="description">
                Flair Dex is a decentralised exchange and automated market marker focused on providing efficient token swaps and deep liquidity for stablecoins and other assets. Flair Dex comes with a unique governance structure using the ve(3,3) system that provides the possibility to maximise gains for all users of the ecosystem.
              </p>
            </div>
          </div>
          <div className="additional-details d-flex mt-5 flex-wrap">
            <div className="graph mb-5">
              <img
                alt={`Project Banner`}
                src={Banner}
              />
            </div>
            <div className="screening p-4 mb-5 flex-sm-grow-1">
              <div className="d-flex flex-row ">
                <CheckCircle sx={{ color: '#E8D33E', fontSize: 30 }} className="me-3" />
                <p className="text-white">Stable and Volatile Swap</p>
              </div>

              <div className="d-flex flex-row ">
                <CheckCircle sx={{ color: '#E8D33E', fontSize: 30 }} className="me-3" />
                <p className="text-white">Permissionless Bribes</p>
              </div>

              <div className="d-flex flex-row">
                <CheckCircle sx={{ color: '#E8D33E', fontSize: 30 }} className="me-3" />
                <p className="text-white">Controlled Emissions</p>
              </div>

              <div className="d-flex flex-row ">
                <CheckCircle sx={{ color: '#E8D33E', fontSize: 30 }} className="me-3" />
                <p className="text-white">Ve(3,3) NFT</p>
              </div>

            </div>
          </div>
          <div className="progress-container d-flex flex-wrap">
            <div className="graph mb-5 p-4">
              <div className="d-flex flex-column progress-details">
                <span>
                  Final Contribution:{" "}
                  {finalContri}
                </span>
                <span>
                  FLDX Allocated:{" "}
                  {fldxAllocation}
                </span>
                <span>
                  veNFT Allocated:{" "}
                  {veNFTAllocation}
                </span>
              </div>

            </div>
            <div className="screening p-4 mb-5 flex-sm-grow-1">
              {address && (
                <>
                    <Button
                      onClick={() => {
                        if (isPendingTxn(pendingTransactions, "refund"))
                          return;
                        onRefund();
                      }}
                      variant="outlined"
                    >
                      {txnButtonText(
                        pendingTransactions,
                        "refund",
                        "refund"
                      )}
                    </Button>
                  <p className="investment-details mt-3">
                    Claimable Refund Amount:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 4,
                    }).format(refund)}
                  </p>
                  <p className="investment-details mt-3">
                    Invested Amount:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 4,
                    }).format(invested)}
                  </p>
                </>
              )}

              {!address && <ConnectMenu showCornors={false}></ConnectMenu>}
            </div>
          </div>
          <div className="table-section d-flex mb-5 flex-column">
            <div className="tabs d-flex align-items-start">
              <Tabs
                value={activeTab}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs"
                centered
              >
                <Tab value="p-details" label="Project Details" />
              </Tabs>
            </div>
            {activeTab === "p-details" && (
              <div className="mt-4 d-flex flex-wrap table-detail px-3">
                <div className="flex-grow-1">
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="2">Token information</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>FLAIR DEX</td>
                      </tr>
                      <tr>
                        <td>Token Symbol</td>
                        <td>FLDX</td>
                      </tr>
                      <tr>
                        <td>Price</td>
                        <td>0.006 USDT</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="spacer"></div>
                <div className="flex-grow-1">
                  <table>
                    <thead>
                    <tr>
                      <th colSpan="2">Supply Information</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>Total Tokens for PreSale</td>
                      <td>20,000,000 FLDX</td>
                    </tr>
                    <tr>
                      <td>Initial Supply</td>
                      <td>100,000,000 FLDX</td>
                    </tr>
                    <tr>
                      <td>Total MarketCap at Launch</td>
                      <td>700,000$</td>
                    </tr>
                    <tr>
                      <td>Launch Price</td>
                      <td>0.007 USDT</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div className="spacer"></div>
                <div className="flex-grow-1">
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="2">Pool Information</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Opens</td>
                        <td>2023-03-15 07:00:00 PM UTC</td>
                      </tr>
                      <tr>
                        <td>Closes</td>
                        <td>2023-03-18 07:00:00 PM UTC</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
