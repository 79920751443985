export const claimList = [
 [
  "0xa5e8e69b620c988f8d784446a50f95e4bbc8441d",
  186000000000,
  186000,
  23553.55046,
  3140473.395,
  785118.3487
 ],
 [
  "0x539da2877f260db9cf39b6b0a51b3b56ba41495d",
  140995769508,
  140995.7695,
  17854.57512,
  2380610.016,
  595152.504
 ],
 [
  "0xc6376540cf443d22cc3c697526bee1f81401e61c",
  99846832343,
  99846.83234,
  12643.80325,
  1685840.433,
  421460.1083
 ],
 [
  "0xcf0e8537880bbad653e6522621aecd285e32577d",
  39760938069,
  39760.93807,
  5035.006781,
  671334.2374,
  167833.5594
 ],
 [
  "0x469adaf766fb35f1a3c2569fe8c57a50f4b39131",
  29999067100,
  29999.0671,
  3798.841617,
  506512.2156,
  126628.0539
 ],
 [
  "0xdc1cb8181a5b6085ff95e93fabbbf97e3d2e999e",
  26022613530,
  26022.61353,
  3295.295381,
  439372.7175,
  109843.1794
 ],
 [
  "0xb412b96dbe138da98be8e475f632e1a8f0037748",
  25863055693,
  25863.05569,
  3275.090255,
  436678.7006,
  109169.6752
 ],
 [
  "0xc27e87cfe1fd2ed6f43dfffbb9e9e46428497a24",
  24993633633,
  24993.63363,
  3164.993608,
  421999.1477,
  105499.7869
 ],
 [
  "0x8e29714a1be1c455d0351a4149bbd0137d568108",
  20000000000,
  20000,
  2532.639835,
  337685.3113,
  84421.32782
 ],
 [
  "0xdcab51dcd7fb10eca44c675f114de522edde8da3",
  19999000000,
  19999,
  2532.513203,
  337668.427,
  84417.10675
 ],
 [
  "0xaf2fb1f2ece28727aab2bf4e5f08daa3ef911260",
  19995800551,
  19995.80055,
  2532.10805,
  337614.4067,
  84403.60167
 ],
 [
  "0x5eeb877a4d8ae876da4c8f6c87b75a1e44efd6c4",
  15664073297,
  15664.0733,
  1983.5728,
  264476.3734,
  66119.09334
 ],
 [
  "0xe086c9b42e57ce6aef9584fe1c6278e1d30fff0a",
  15117066401,
  15117.0664,
  1914.304228,
  255240.5637,
  63810.14092
 ],
 [
  "0x44f7d684401a3c6fe7e650f2589579c6f47fe3de",
  13500000000,
  13500,
  1709.531888,
  227937.5851,
  56984.39628
 ],
 [
  "0xcd54877093f9804a9b08ea902732fc6f38ba74f3",
  12000000000,
  12000,
  1519.583901,
  202611.1868,
  50652.79669
 ],
 [
  "0x42a1ef5ffdaf134eb958814e443db9c244375c8f",
  11806707510,
  11806.70751,
  1495.106888,
  199347.585,
  49836.89626
 ],
 [
  "0x2b7403bdb196fa5fc0e4f779600a084be0d8422e",
  11045000000,
  11045,
  1398.650349,
  186486.7132,
  46621.67829
 ],
 [
  "0xe684dc05c16851c65edb5d9fc3b11bce4f427eb2",
  10561073815,
  10561.07382,
  1337.369812,
  178315.9749,
  44578.99373
 ],
 [
  "0xbe7dd82facd47d3598803f44cb980078466aeba3",
  10334012073,
  10334.01207,
  1308.616531,
  174482.2042,
  43620.55105
 ],
 [
  "0x6692f937944d0c1b288166b9424a13cc6e4875ef",
  10000000000,
  10000,
  1266.319917,
  168842.6556,
  42210.66391
 ],
 [
  "0x272433d9e5d73db3f2d8dec5c7d54ff922794466",
  9999000000,
  9999,
  1266.193285,
  168825.7714,
  42206.44284
 ],
 [
  "0x9c1dda14950d566559d68d31054f23fae09932ea",
  9130566801,
  9130.566801,
  1156.22186,
  154162.9146,
  38540.72866
 ],
 [
  "0x3e496bf73e1108250a5286fdf83bceeb0f36eb38",
  9100000000,
  9100,
  1152.351125,
  153646.8166,
  38411.70416
 ],
 [
  "0x031c4f4e236b507354449bc4ae242a0e27451782",
  8788046264,
  8788.046264,
  1112.847802,
  148379.7069,
  37094.92673
 ],
 [
  "0x911da057b7f735bc54e9ffbe1143dda363dba6fb",
  7911788268,
  7911.788268,
  1001.885507,
  133584.7342,
  33396.18355
 ],
 [
  "0x06069b90a493d3e5dfd508a97288eb742e950edc",
  7404080902,
  7404.080902,
  937.5935116,
  125012.4682,
  31253.11705
 ],
 [
  "0x3ed1d19e887bd24c172a207355c3a76ab09e0d98",
  5982297382,
  5982.297382,
  757.5502326,
  101006.6977,
  25251.67442
 ],
 [
  "0xaa6a825e55e934823392b7c29e36b042d950237f",
  5813302120,
  5813.30212,
  736.150026,
  98153.3368,
  24538.3342
 ],
 [
  "0x73f843aa0c343ab4693f966865caa37a0d6c1007",
  5021016218,
  5021.016218,
  635.8212842,
  84776.17123,
  21194.04281
 ],
 [
  "0x36d363103ab6dc1b2251a9747ef95f5a643cd0ee",
  4999000565,
  4999.000565,
  633.0333982,
  84404.4531,
  21101.11327
 ],
 [
  "0xcb31e22cdfe43334181af1f778fa2109b9ed5b0a",
  4989445866,
  4989.445866,
  631.8234677,
  84243.12902,
  21060.78226
 ],
 [
  "0xfa460271bd45a2bbbc53485392cf6c0952087513",
  4983494872,
  4983.494872,
  631.0698814,
  84142.65086,
  21035.66271
 ],
 [
  "0x4fd3f0fd173ffb5bbfcec096a4224846f8b573ab",
  4981070093,
  4981.070093,
  630.7628268,
  84101.71024,
  21025.42756
 ],
 [
  "0xba4f1b3c4f7c24944574b06387330cc1fed51e29",
  3505000000,
  3505,
  443.845131,
  59179.3508,
  14794.8377
 ],
 [
  "0x867b5c1f44eaccc2e5555f6d3ef9ddc256a77b0f",
  3323000000,
  3323,
  420.7981085,
  56106.41447,
  14026.60362
 ],
 [
  "0x5550d9f1d786f5e0f32470d8f333181930e4b55c",
  3108244205,
  3108.244205,
  393.6031545,
  52480.4206,
  13120.10515
 ],
 [
  "0x8e8a51872689bf01472f479e1a064875cc245102",
  3000000000,
  3000,
  379.8959752,
  50652.79669,
  12663.19917
 ],
 [
  "0x304fab728f47c37f036dc2623ecc631edccfe9c4",
  2999000000,
  2999,
  379.7693432,
  50635.91243,
  12658.97811
 ],
 [
  "0x40faebbca9316f50168e260a316373a86be8505f",
  2999000000,
  2999,
  379.7693432,
  50635.91243,
  12658.97811
 ],
 [
  "0xba133a363d3aa68b0373a7b6f32e3b779e2634ac",
  2925839395,
  2925.839395,
  370.5048701,
  49400.64934,
  12350.16234
 ],
 [
  "0xbc3a38c981b13625faf7729ff105cb6e15bdde3a",
  2769912825,
  2769.912825,
  350.759578,
  46767.94373,
  11691.98593
 ],
 [
  "0x9259a58f7a9642b03b2cbd19e19a7a04a4e3f8fb",
  2498330290,
  2498.33029,
  316.3685406,
  42182.47208,
  10545.61802
 ],
 [
  "0xad85f6a7d50a9e2302f38f95eb1ebced0f77dd90",
  2451314531,
  2451.314531,
  310.4148414,
  41388.64552,
  10347.16138
 ],
 [
  "0x6666e1c7663e32eeae4b4ba79c6e73765aa41101",
  2098414361,
  2098.414361,
  265.72639,
  35430.18534,
  8857.546334
 ],
 [
  "0x5bb96c35a68cba037d0f261c67477416db137f03",
  2001385798,
  2001.385798,
  253.4394698,
  33791.92931,
  8447.982327
 ],
 [
  "0x9f758db3e183b868ca50d09f1a8971f404b27603",
  2001010293,
  2001.010293,
  253.3919189,
  33785.58918,
  8446.397296
 ],
 [
  "0xabc00c1ad0c57878d116dfce50172055557af97b",
  2000000500,
  2000.0005,
  253.2640468,
  33768.53957,
  8442.134893
 ],
 [
  "0x2bf6607e66337476ca005f070d9d84216227218f",
  2000000000,
  2000,
  253.2639835,
  33768.53113,
  8442.132782
 ],
 [
  "0x5a03277b30a28b614f227e1f6c50aa5385bb6c50",
  2000000000,
  2000,
  253.2639835,
  33768.53113,
  8442.132782
 ],
 [
  "0x54e40865337a0b5c85417866cf4043b6bd1e6142",
  2000000000,
  2000,
  253.2639835,
  33768.53113,
  8442.132782
 ],
 [
  "0x6f106e0ef498a594cce8280976822fa3798a35cb",
  2000000000,
  2000,
  253.2639835,
  33768.53113,
  8442.132782
 ],
 [
  "0xbde70adfd16ab60f7307787c97a8fe959a80827a",
  2000000000,
  2000,
  253.2639835,
  33768.53113,
  8442.132782
 ],
 [
  "0x6b3b81076fabe7f765caa324ab651413e29743be",
  2000000000,
  2000,
  253.2639835,
  33768.53113,
  8442.132782
 ],
 [
  "0x99e8d12138c1b9c78c1f5cd9904c56e134ebff32",
  2000000000,
  2000,
  253.2639835,
  33768.53113,
  8442.132782
 ],
 [
  "0xac65e5471ad6fa25a6b176397510eccd87af4fcd",
  2000000000,
  2000,
  253.2639835,
  33768.53113,
  8442.132782
 ],
 [
  "0x0feaa9d8eba3f9d220207ed4830a02d58eec928e",
  1999905502,
  1999.905502,
  253.252017,
  33766.9356,
  8441.7339
 ],
 [
  "0xb5dba2f9d7637c95fb2a368dd4f286f062fbb4a4",
  1994100375,
  1994.100375,
  252.5169022,
  33668.92029,
  8417.230073
 ],
 [
  "0x0fe1aa632a2837239f218e3efad21e8018a9f4ef",
  1993700693,
  1993.700693,
  252.4662897,
  33662.17196,
  8415.542989
 ],
 [
  "0xa9e9e0414d52658515eb9db6dcdc6055396a031a",
  1911880000,
  1911.88,
  242.1051724,
  32280.68965,
  8070.172412
 ],
 [
  "0x14c49fb42d4aca62f905e3fd4eecba1932f58c90",
  1719169455,
  1719.169455,
  217.7018522,
  29026.91363,
  7256.728407
 ],
 [
  "0xf168b094577aff84e0c1fa67a661775fbac7e6fa",
  1708867537,
  1708.867537,
  216.3972998,
  28852.97331,
  7213.243327
 ],
 [
  "0xb4a77bc400b82668965b69ec3b718a4bc4782c06",
  1626390023,
  1626.390023,
  205.9530079,
  27460.40106,
  6865.100265
 ],
 [
  "0x7b901288d982c208f74d2d68345bef338f23406f",
  1512731909,
  1512.731909,
  191.5602546,
  25541.36728,
  6385.34182
 ],
 [
  "0xd76ead6b28824ba5c66a96e307da3363b1bbfccf",
  1499000000,
  1499,
  189.8213556,
  25309.51408,
  6327.37852
 ],
 [
  "0x3b1184e98e8d2a36a28ba8270827ec4041e6d3d5",
  1495669780,
  1495.66978,
  189.3996432,
  25253.28576,
  6313.32144
 ],
 [
  "0x276292b77c30df7d6da80852b9b75be965a2aafd",
  1488212115,
  1488.212115,
  188.4552642,
  25127.36857,
  6281.842141
 ],
 [
  "0xa207ac236dfdee6f885d0cc1d0c8100b46fa91ee",
  1401000000,
  1401,
  177.4114204,
  23654.85606,
  5913.714014
 ],
 [
  "0xf33c07b1e0e16f97fd04f9bce4db4783eaab3815",
  1324427510,
  1324.42751,
  167.7148935,
  22361.9858,
  5590.49645
 ],
 [
  "0x0f8aca6bb8d86bba87892efab1e19491c97387b7",
  1318877707,
  1318.877707,
  167.0121109,
  22268.28145,
  5567.070363
 ],
 [
  "0xe5c9d04a861a9c3c08879a990d51d81ebcdbc6df",
  1246000000,
  1246,
  157.7834617,
  21037.79489,
  5259.448723
 ],
 [
  "0x75e84d0ae9f540e7e9fddb4c7c9bb79747a6d7e9",
  1020735207,
  1020.735207,
  129.2577323,
  17234.36431,
  4308.591076
 ],
 [
  "0x5fe5bc079b70aea2d7a4897f757ca7f20fd2e979",
  1013894396,
  1013.894396,
  128.3914668,
  17118.86224,
  4279.715559
 ],
 [
  "0x1f742217aef76d09c3c5bb3c967fbf94d22f1828",
  1000825149,
  1000.825149,
  126.736482,
  16898.1976,
  4224.5494
 ],
 [
  "0x2225e8a95fbf7cd32b9b9ac3d87fa88c6957cb6f",
  1000000630,
  1000.00063,
  126.6320715,
  16884.2762,
  4221.06905
 ],
 [
  "0x228c9a3eefcd15e28f02b83cb132a2266d8a49c2",
  1000000000,
  1000,
  126.6319917,
  16884.26556,
  4221.066391
 ],
 [
  "0x8c01b3606761b2aa382afbc7fe4f9b1335fe0860",
  999346604,
  999.346604,
  126.5492509,
  16873.23345,
  4218.308363
 ],
 [
  "0x4413a906a012f06bd0abda5eec45f7d67b449284",
  999238145,
  999.238145,
  126.5355165,
  16871.4022,
  4217.850551
 ],
 [
  "0x140882b6e1c999789a64de182df142b72bc9415b",
  999000000,
  999,
  126.5053597,
  16867.3813,
  4216.845325
 ],
 [
  "0xa7f189d9033404e1bcf3070a7eafba19e2dd10b8",
  998394910,
  998.39491,
  126.428736,
  16857.1648,
  4214.2912
 ],
 [
  "0xa4fe067c4646c7b7ca8944c60490fdb176e3acd3",
  998002068,
  998.002068,
  126.3789896,
  16850.53195,
  4212.632987
 ],
 [
  "0x05d9eacc1d714237e3aaf32313b6b6ff022a5003",
  997702727,
  997.702727,
  126.3410835,
  16845.4778,
  4211.369449
 ],
 [
  "0x44f2cdf495e9f93bcc91dc421d1bb00ad95b617b",
  997553025,
  997.553025,
  126.3221264,
  16842.95019,
  4210.737547
 ],
 [
  "0x80039dc3d5bb48ec4bd822c4e8828574fdcc51a6",
  984666963,
  984.666963,
  124.6903387,
  16625.3785,
  4156.344624
 ],
 [
  "0x21d5aa699e7ca294212c1e9eaec3cae8034eb231",
  977394637,
  977.394637,
  123.7694296,
  16502.59061,
  4125.647653
 ],
 [
  "0x13108ab5c6eb7efdcd7ba6f8cc55d265eae6176f",
  969360124,
  969.360124,
  122.7520032,
  16366.93376,
  4091.73344
 ],
 [
  "0x5ffd5cec88490d984d3f83496592b65c1225871c",
  944241261,
  944.241261,
  119.5711516,
  15942.82021,
  3985.705052
 ],
 [
  "0xac1dcd58450dcaa0e114cd702f3d4a8507579a3d",
  926369102,
  926.369102,
  117.3079645,
  15641.06193,
  3910.265482
 ],
 [
  "0xa2e59c9e815ac0492bedc2dcc168dc41992b63b1",
  917263400,
  917.2634,
  116.1548913,
  15487.31884,
  3871.829709
 ],
 [
  "0x21988d0ee2d2383b278bd8fc70c2065b7063111e",
  830097433,
  830.097433,
  105.1168913,
  14015.5855,
  3503.896376
 ],
 [
  "0x16639aedf7e0c7ee7de9c5a431cef6cde970155a",
  750426356,
  750.426356,
  95.02798411,
  12670.39788,
  3167.59947
 ],
 [
  "0xfa537c114edd1d85a6019222c1c0e90707d2088d",
  701165739,
  701.165739,
  88.79001406,
  11838.66854,
  2959.667135
 ],
 [
  "0xd8533d518b4a70d8f8a591ca34797d3cce5e1aff",
  700261948,
  700.261948,
  88.67556521,
  11823.40869,
  2955.852174
 ],
 [
  "0x6887b5852847dd89d4c86dfaefab5b0b236dcd8a",
  639974725,
  639.974725,
  81.04127408,
  10805.50321,
  2701.375803
 ],
 [
  "0xa8dc7e1dd009d10449a2f29a724018e05461b017",
  621295563,
  621.295563,
  78.6758946,
  10490.11928,
  2622.52982
 ],
 [
  "0x94e08736426948c5f463ac243416f0473f680b2b",
  618074388,
  618.074388,
  78.26799079,
  10435.73211,
  2608.933026
 ],
 [
  "0xaf9fe0c5b59f2f112864f05fd041dd084c0d806f",
  588266663,
  588.266663,
  74.49337921,
  9932.450561,
  2483.11264
 ],
 [
  "0xb6c7fee12600d34289da2eda03c66ba5d2923d65",
  577963359,
  577.963359,
  73.1886513,
  9758.48684,
  2439.62171
 ],
 [
  "0x2bcdca8c689b4aa460b66ffde1d6b1761d41d5df",
  507270611,
  507.270611,
  64.23668782,
  8564.891709,
  2141.222927
 ],
 [
  "0x70bed9005f71ec0298a154911df98b9221314c21",
  502100000,
  502.1,
  63.58192305,
  8477.58974,
  2119.397435
 ],
 [
  "0x3bce96ab4cabd0b8514e42a84a074403413863a8",
  500000000,
  500,
  63.31599587,
  8442.132782,
  2110.533196
 ],
 [
  "0x2303d64736eb0ca0a257d896e6ec715cb4182f7c",
  500000000,
  500,
  63.31599587,
  8442.132782,
  2110.533196
 ],
 [
  "0xf0f158b9bbdcfedde2c725a999e64a58f7fe2891",
  500000000,
  500,
  63.31599587,
  8442.132782,
  2110.533196
 ],
 [
  "0x9a414b1eefacd52209e2a5cbc20e4d9ff1e280be",
  499745975,
  499.745975,
  63.28382817,
  8437.843757,
  2109.460939
 ],
 [
  "0x6851f2157f6f851434a479ae5e24ab0d288ed754",
  499000000,
  499,
  63.18936387,
  8425.248517,
  2106.312129
 ],
 [
  "0xfc38631e4f54c47cd63ddb9cdf2a2e0ee23987a2",
  497671152,
  497.671152,
  63.02108921,
  8402.811894,
  2100.702974
 ],
 [
  "0x0737467164f8bb1ef64f006612edb6035aea6efb",
  400000000,
  400,
  50.65279669,
  6753.706226,
  1688.426556
 ],
 [
  "0xdd15553673126d386f9e735d0cc56a96e59cc095",
  398570593,
  398.570593,
  50.47178804,
  6729.571738,
  1682.392935
 ],
 [
  "0x89d022f95dc5073b7479699044fdf6e3bc74d3b3",
  398133538,
  398.133538,
  50.41644289,
  6722.192386,
  1680.548096
 ],
 [
  "0x6b9a20093e8af52ea7d8923c3fc8ff25ea4d941c",
  355183647,
  355.183647,
  44.97761265,
  5997.01502,
  1499.253755
 ],
 [
  "0x3ec911e92dc4c64cb4f6fca7c09cceb6a93b2956",
  350000000,
  350,
  44.32119711,
  5909.492947,
  1477.373237
 ],
 [
  "0xc9220d0abf5ba64a3268b53713bbb4b6cb52ec1a",
  349185645,
  349.185645,
  44.21807371,
  5895.743161,
  1473.93579
 ],
 [
  "0xe4ec13946ce37ae7b3ea6aac315b486dad7766f2",
  349108783,
  349.108783,
  44.20834052,
  5894.445403,
  1473.611351
 ],
 [
  "0x87e15fbf6f9f9c31cc75bfc11a4210cb3a9bac2d",
  349000000,
  349,
  44.19456511,
  5892.608682,
  1473.15217
 ],
 [
  "0xa6e2171e9ad71a6c4a5530f4043d60bf99b4e4c2",
  347281317,
  347.281317,
  43.97692486,
  5863.589982,
  1465.897495
 ],
 [
  "0xe014b7fe646eb0ae2aef82e9c2a7092986a3f057",
  325629416,
  325.629416,
  41.23510151,
  5498.013535,
  1374.503384
 ],
 [
  "0x96b4e28849b2557b66778ecdcbcca259c5f6c6e3",
  306952505,
  306.952505,
  38.87000708,
  5182.66761,
  1295.666903
 ],
 [
  "0x5b4a08e006917f68c35d450379d91c430d479296",
  300000000,
  300,
  37.98959752,
  5065.279669,
  1266.319917
 ],
 [
  "0x75369ddb544989c38029e118d3811c2f15345e17",
  300000000,
  300,
  37.98959752,
  5065.279669,
  1266.319917
 ],
 [
  "0x8e858dcb17a1250f786db5a51e4e5e1338985d8e",
  299700000,
  299.7,
  37.95160792,
  5060.21439,
  1265.053597
 ],
 [
  "0xe0f97a8743bab540df1f4e9818cbb2e3fc2b742e",
  297600301,
  297.600301,
  37.68571886,
  5024.762514,
  1256.190629
 ],
 [
  "0xbd3ad03cf720cf508f82b799cc4013958615e443",
  262597241,
  262.597241,
  33.25321165,
  4433.761553,
  1108.440388
 ],
 [
  "0x3fe5041f8ff5d8efa33087699d95bee807a44940",
  249950000,
  249.95,
  31.65166633,
  4220.222178,
  1055.055544
 ],
 [
  "0x0777e98c2307ad519ca2a6f08c6ab32071a88b85",
  231792173,
  231.792173,
  29.35230453,
  3913.640605,
  978.4101512
 ],
 [
  "0x88c83537c12a90e8f361912a7239e9a8b9dfd92c",
  201000000,
  201,
  25.45303034,
  3393.737378,
  848.4343446
 ],
 [
  "0x2a8ce51e7c0a1ee60e20bd859ecd07fd2530a3f3",
  199195883,
  199.195883,
  25.22457141,
  3363.276188,
  840.819047
 ],
 [
  "0x24225e5af9d57135945df4a990fd4af0bba0b832",
  198680000,
  198.68,
  25.15924412,
  3354.565882,
  838.6414706
 ],
 [
  "0xaa970e6bd6e187492f8327e514c9e8c36c81f11e",
  194086323,
  194.086323,
  24.57753765,
  3277.00502,
  819.251255
 ],
 [
  "0xca564070c1a207600126070996e843337753fd46",
  191721993,
  191.721993,
  24.27813783,
  3237.085044,
  809.2712611
 ],
 [
  "0xac5dbd7f2f71d2fb14e5f433faeb6444db9d4e20",
  180039846,
  180.039846,
  22.79880429,
  3039.840572,
  759.960143
 ],
 [
  "0xf943be50ee14abcaa164b5b10e6f5ee31ed2cd86",
  179385692,
  179.385692,
  22.71596747,
  3028.795662,
  757.1989155
 ],
 [
  "0x609470c2f08ff626078ba64ceb905d73b155089d",
  156575378,
  156.575378,
  19.82745197,
  2643.660263,
  660.9150657
 ],
 [
  "0x47f4e5ca3d84348004a531be3986c850b6e76e08",
  151301142,
  151.301142,
  19.15956496,
  2554.608662,
  638.6521654
 ],
 [
  "0x56ebc608fd6b8f8cef2775a3ee0dddaec01a7000",
  132643680,
  132.64368,
  16.79693339,
  2239.591119,
  559.8977796
 ],
 [
  "0x9ea268544356ce16f59f4ded58915fdab019af9d",
  120795888,
  120.795888,
  15.29662389,
  2039.549852,
  509.887463
 ],
 [
  "0xa3e49418ec400cb1c03109b6a1f777e7dc53f48c",
  120128330,
  120.12833,
  15.21208969,
  2028.278626,
  507.0696564
 ],
 [
  "0xc0147f91ef839a9fb2e0f976ecb83c17449ac758",
  112740101,
  112.740101,
  14.27650354,
  1903.533805,
  475.8834513
 ],
 [
  "0x352bf5e59a4c1a0e4403278fe5c9189f63afde3c",
  109743184,
  109.743184,
  13.89699797,
  1852.933063,
  463.2332656
 ],
 [
  "0x6b635052d78b3d1f8f8c6fdea58fcd695484f20f",
  102000000,
  102,
  12.91646316,
  1722.195088,
  430.5487719
 ],
 [
  "0x44e3daf6bfa2905ea385cc56a4324819755b8fee",
  100853522,
  100.853522,
  12.77128236,
  1702.837649,
  425.7094121
 ],
 [
  "0x2abfc85d4ea442d41b5e974b7603051ccc4bfcb2",
  100000000,
  100,
  12.66319917,
  1688.426556,
  422.1066391
 ],
 [
  "0x4c890dc20f7d99d0135396a08d07d1518a45a1dd",
  100000000,
  100,
  12.66319917,
  1688.426556,
  422.1066391
 ],
 [
  "0xe13622244027d19ea18e79f15a2e7279fd883104",
  100000000,
  100,
  12.66319917,
  1688.426556,
  422.1066391
 ],
 [
  "0x9e30bdbdc4df47cb542222beae91270c06d4bcef",
  100000000,
  100,
  12.66319917,
  1688.426556,
  422.1066391
 ],
 [
  "0x62dbe9c08a304cc74d9105fd91f7db6821887ccb",
  100000000,
  100,
  12.66319917,
  1688.426556,
  422.1066391
 ],
 [
  "0x5fc026ab7f7c6ac62c62e4382f7ff3d37e2c2a75",
  99188677,
  99.188677,
  12.56045973,
  1674.727963,
  418.6819909
 ],
 [
  "0xcac104a1e39eb8c0df120001633f03f5f53cb428",
  77000000,
  77,
  9.750663363,
  1300.088448,
  325.0221121
 ],
 [
  "0x57fd1260b14914e8e9dd468b6e2130eca353f749",
  60191304,
  60.191304,
  7.62214471,
  1016.285961,
  254.0714903
 ],
 [
  "0x9eaaedcfdaf6722817246441bc50f12f97bf186f",
  50621796,
  50.621796,
  6.410338853,
  854.711847,
  213.6779618
 ],
 [
  "0x4db19e33d2cfb29440437876835c4ec5fb16199a",
  50000000,
  50,
  6.331599587,
  844.2132782,
  211.0533196
 ],
 [
  "0xd6dc4a8465b36e4af645dd83a39c5ccc18b22313",
  49100000,
  49.1,
  6.217630794,
  829.0174392,
  207.2543598
 ],
 [
  "0xc6f30b9e42af51dc3a730e2e810b2a9eecc48f31",
  49000000,
  49,
  6.204967595,
  827.3290126,
  206.8322532
 ],
 [
  "0xa9f152266b618ae2629f356f7185b660c3eb889a",
  17350078,
  17.350078,
  2.197074934,
  292.9433245,
  73.23583113
 ],
 [
  "0xc929ccb5e214d55bd8cec88e2b83709c2adaeeed",
  16477219,
  16.477219,
  2.08654306,
  278.2057414,
  69.55143534
 ],
 [
  "0x5a34f25040ba6e12daea0512d4d2a0043ecc9292",
  10000000,
  10,
  1.266319917,
  168.8426556,
  42.21066391
 ],
 [
  "0xd71c552a4954673a30893bf1db0a77f1afa1accd",
  10000000,
  10,
  1.266319917,
  168.8426556,
  42.21066391
 ],
 [
  "0x3850faa53550bd0466b50f44892f92eace080457",
  8353150,
  8.35315,
  1.057776022,
  141.0368029,
  35.25920072
 ],
 [
  "0xf7c802e795c749066e917f30b34c6488e5b10da3",
  2000000,
  2,
  0.2532639835,
  33.76853113,
  8.442132782
 ],
 [
  "0xa38a161660989c9a3825444944d6288c8f439ad4",
  1535400,
  1.5354,
  0.1944307601,
  25.92410135,
  6.481025337
 ]
]


