import * as React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { ConnectMenu } from "../index";
import "./header.css";

const links = [
  {
    id: 1,
    text: "Projects",
    path: "/projects",
  },
];

function CustomLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <div>
      <Link className={`nav-link ${match && "active"}`} to={to} {...props}>
        {children}
      </Link>
    </div>
  );
}

export const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        <a className="navbar-brand audio-wide" href="/">
          Flair Dex
        </a>
        <span></span>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
        </div>
        <div className="nav-button">
          <ConnectMenu showCornors={false}></ConnectMenu>
        </div>
      </div>
    </nav>
  );
};
